<ion-tabs>
  <ion-tab-bar slot="bottom">
    <ion-tab-button tab="translate">
      <ion-icon aria-hidden="true" name="home" />
      <ion-label>Home</ion-label>
    </ion-tab-button>

    <!--    &lt;!&ndash;-->
    <!--    <ion-tab-button tab="converse" [disabled]="true">-->
    <!--      <ion-icon aria-hidden="true" name="chatbubbles" />-->
    <!--      <ion-label>Converse</ion-label>-->
    <!--    </ion-tab-button>-->

    <!--    <ion-tab-button tab="avatar" [disabled]="true">-->
    <!--      <ion-icon aria-hidden="true" name="sparkles" />-->
    <!--      <ion-label>Avatar</ion-label>-->
    <!--    </ion-tab-button>-->
    <!--    &ndash;&gt;-->

    <ion-tab-button tab="settings">
      <ion-icon aria-hidden="true" name="person" />
      <ion-label>Settings</ion-label>
    </ion-tab-button>
  </ion-tab-bar>
</ion-tabs>
